
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import FormInput from '../components/FormInput.vue';
import FaqItem from '../components/FaqItem.vue';
import { Customer } from '@/models/customers/customer.class';
import { User } from '@/models/users/user.class';
import { namespace } from 'vuex-class';
import { cloneDeep } from 'lodash';

const customersModule = namespace('customers');
const authModule = namespace('auth');
const usersModule = namespace('users');

@Component({
  components: { ValidationProvider, ValidationObserver, FormInput, FaqItem },
})
export default class InvoiceData extends Vue {
  isLoading = false;
  successMessageSeconds = 0;
  currentCustomer: Customer = new Customer();
  @authModule.Getter('authenticatedUser')
  user!: User;

  @customersModule.Action('FETCH_ONE')
  fetchCustomerById!: (id: string) => Customer;

  @customersModule.Getter('GET')
  customer!: Customer;

  // add update customer to customersmodule
  @usersModule.Action('updateCustomer')
  updateCustomer!: (customer: Customer) => Customer;

  @Watch('user', { immediate: true })
  async onUserChanged(user) {
    await this.fetchCustomerById(user.customer._id);
  }

  @Watch('customer', { immediate: true })
  onCustomerChanged(customer) {
    this.currentCustomer = cloneDeep(customer);
  }

  trimInput(value: string) {
    this.currentCustomer.zip = value.split(' ').join('');
  }

  onSubmit() {
    this.isLoading = true;
    const customer = this.updateCustomer(this.currentCustomer);
    if (customer) {
      this.successMessageSeconds = 8;
      window.scrollTo(0, 0);
    }
    this.isLoading = false;
  }
}
